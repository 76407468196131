import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Img from "gatsby-image"
import Div100vh from 'react-div-100vh'

import SEO from '~/components/seo'

import '~/styles/MainPage.scss'

import Scroll from '~/svgs/scroll.svg'

const IndexPage = ({ data }) => {

  var header, headerTop;

  function toggleScrollClass() {
    if (typeof window !== 'undefined') {
      if (!header || !headerTop) {
        header = document.getElementById('header');
        headerTop = parseInt(window.getComputedStyle(header).getPropertyValue('top'));
      }
      if (window.scrollY > headerTop) {
        header.classList.remove('global-header-intro');
      }
      else {
        headerTop = parseInt(window.getComputedStyle(header).getPropertyValue('top'));
        header.classList.add('global-header-intro');
      }
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      toggleScrollClass();
      window.addEventListener('scroll', toggleScrollClass);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', toggleScrollClass);
        header.classList.remove('global-header-intro');
      }
    };
  });

  return (
    <>
      <SEO title="Products" />
      <Helmet
        bodyAttributes={{
          class: "main-page"
        }}
      />
      <main className="main">
        <Div100vh as="section" className="main-intro" id="intro">
          <div className="main-intro-inner">
            <div className="main-intro-text">
              <div className="main-intro-text-title">We&nbsp;make the world safer</div>
              <div className="main-intro-text-subtitle">Pacific Atlantic Holdings, HK&nbsp;Limited was formed in&nbsp;2003 in&nbsp;Hong Kong as&nbsp;a&nbsp;logistics manufacturing group to&nbsp;assist in&nbsp;rapid production and movement of&nbsp;goods between China, United States and European Union. Over the last 17&nbsp;years we&nbsp;grew and expanded to&nbsp;deal with many situations and crisis as&nbsp;demand for our services escalated.</div>
            </div>
            <AnchorLink className="main-intro-scroll" href='#products'>
              <Scroll alt="Scroll down" className="main-intro-scroll-icon" />
            </AnchorLink>
          </div>
        </Div100vh>
        <section className="main-products" id="products">
          <div className="main-products-item main-products-item-ltr">
            <Img className="main-products-item-image" fluid={data.medMask.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }} alt="" />
            <div className="main-products-item-text">
              <div className="main-products-item-text-num">01</div>
              <div className="main-products-item-text-title">Disposable Meltblown Cloth Non-Woven Mask</div>
              <div className="main-products-item-text-description">The 1st and 3rd layers are made of water-repellent and breathable material with low pile separation. The 2nd layer is made of meltblown non-woven fabric which is fluffy and soft. It absorbs non-oily particles from the air, increases the filtering efficiency and reduces breathing resistance.</div>
              <Link to="/l1-mask" className="main-products-item-text-button">Learn more</Link>
            </div>
          </div>
          <div className="main-products-item main-products-item-rtr">
            <Img className="main-products-item-image" fluid={data.n95Mask.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }} alt="" />
            <div className="main-products-item-text">
              <div className="main-products-item-text-num">02</div>
              <div className="main-products-item-text-title">N95 Respirator Mask with Antibacterial Filter</div>
              <div className="main-products-item-text-description">The 1st and 4th layers are made from water-repellent and breathable material with low pile separation. The 3rd layer is made of meltblown non-woven fabric which is fluffy and soft. It absorbs non-oily particles from the air, increases the filtering efficiency and reduces breathing difficulty.</div>
              <Link to="/n95-mask" className="main-products-item-text-button">Learn more</Link>
            </div>
          </div>
          <div className="main-products-item main-products-item-ltr">
            <Img className="main-products-item-image" fluid={data.siliconeMask.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }} alt="" />
            <div className="main-products-item-text">
              <div className="main-products-item-text-num">03</div>
              <div className="main-products-item-text-title">Protective Silicone Face Mask</div>
              <div className="main-products-item-text-description">The mask is made out of food grade silicone and uses 4 ply disposable filters. The 3rd layer of the filter is made of meltblown non-woven fabric which is fluffy and soft. It absorbs non-oily particles from the air, increases the filtering efficiency and reduces breathing difficulty.</div>
              <Link to="/silicone-mask" className="main-products-item-text-button">Learn more</Link>
            </div>
          </div>
        </section>
        <section className="main-machines" id="machines">
          <div className="main-machines-title">Production lines</div>
          <div className="main-machines-description">
            We supply and support production equipment. You can find detailed information on the pages of the equipment you are interested in
          </div>
          <ol className="main-machines-list">
            <li><Link to="/3-layers-flat-disposable-mask-production-line">3 layers flat disposable mask production line</Link></li>
            <li><Link to="/3d-disposable-mask-production-line">3D disposable mask production line</Link></li>
            <li><Link to="/n95-flat-piece-production-line">N95 flat piece production line</Link></li>
            <li><Link to="/n95-cup-shape-production-line">N95 cup shape production line</Link></li>
          </ol>
        </section>
        <section className="main-also">
          <div className="main-also-title">We also produce</div>
          <div className="main-also-list">
            <div className="main-also-list-item big" style={{"background-color": "#ededed"}}>
              <div className="main-also-list-item-title">Waste tire comprehensive treatment equipment</div>
              <div className="main-also-list-item-image-wrapper"><Img className="main-also-list-item-image" fluid={data.pyrolysisPlant.childImageSharp.fluid} alt="" /></div>
            </div>
            <div className="main-also-list-item">
              <div className="main-also-list-item-title">Vacuum cleaner filters</div>
              <div className="main-also-list-item-image-wrapper"><Img className="main-also-list-item-image" fluid={data.vacuumFilter.childImageSharp.fluid} alt="" /></div>
            </div>
            <div className="main-also-list-item">
              <div className="main-also-list-item-title">Water filters</div>
              <div className="main-also-list-item-image-wrapper"><Img className="main-also-list-item-image" fluid={data.waterFilter.childImageSharp.fluid} alt="" /></div>
            </div>
            <div className="main-also-list-item">
              <div className="main-also-list-item-title">AC filters</div>
              <div className="main-also-list-item-image-wrapper"><Img className="main-also-list-item-image" fluid={data.acFilter.childImageSharp.fluid} alt="" /></div>
            </div>
            <div className="main-also-list-item">
              <div className="main-also-list-item-title">Car air filters</div>
              <div className="main-also-list-item-image-wrapper"><Img className="main-also-list-item-image" fluid={data.carFilter.childImageSharp.fluid} alt="" /></div>
            </div>
            <div className="main-also-list-item">
              <div className="main-also-list-item-title">Nitrile gloves</div>
              <div className="main-also-list-item-image-wrapper"><Img className="main-also-list-item-image" fluid={data.nitrileGloves.childImageSharp.fluid} alt="" /></div>
            </div>
            <div className="main-also-list-item">
              <div className="main-also-list-item-title">Nitrile production</div>
              <div className="main-also-list-item-image-wrapper"><Img className="main-also-list-item-image" fluid={data.nitrileRings.childImageSharp.fluid} alt="" /></div>
            </div>
            <div className="main-also-list-item big">
              <div className="main-also-list-item-title">Waste tire comprehensive treatment project</div>
              <div className="main-also-list-item-image-wrapper"><Img className="main-also-list-item-image" fluid={data.wasteTire.childImageSharp.fluid} alt="" /></div>
            </div>
            <div className="main-also-list-item">
              <div className="main-also-list-item-title">Micro fiber production</div>
              <div className="main-also-list-item-image-wrapper"><Img className="main-also-list-item-image" fluid={data.microFiber.childImageSharp.fluid} alt="" /></div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export const query = graphql`
query {
  medMask: file(relativePath: { eq: "med-mask.jpg" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  n95Mask: file(relativePath: { eq: "n95-mask.jpg" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  siliconeMask: file(relativePath: { eq: "siliconed-mask.jpg" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  pyrolysisPlant: file(relativePath: { eq: "pyrolysis-plant.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  vacuumFilter: file(relativePath: { eq: "vacuum-filter.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  waterFilter: file(relativePath: { eq: "water-filter.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  acFilter: file(relativePath: { eq: "ac-filter.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  carFilter: file(relativePath: { eq: "car-filter.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  nitrileGloves: file(relativePath: { eq: "nitrile-golves.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  nitrileRings: file(relativePath: { eq: "nitrile-rings.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  microFiber: file(relativePath: { eq: "micro-fiber.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  wasteTire: file(relativePath: { eq: "waste-tire.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
}
`

export default IndexPage
